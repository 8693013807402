import { createContext, useEffect, useReducer } from "react";
import { getLoggedInUserRequest } from "../services/auth/auth.service";
import { IUser } from "../interfaces/user.interface";
import { ITenantSettings } from "../interfaces/setting.interface";
import { IOrganization } from "../interfaces/organization.interface";
import { cloneDeep } from "lodash";

export interface IAuthContextState {
    user?: IUser;
    token?: any;
    loading?: boolean;
}

export interface IAuthContextPayload extends IAuthContextState {
    settings?: ITenantSettings;
    organization?: IOrganization;
}

export interface IAuthProviderPayload {
    action: AuthActions;
    data: IAuthContextPayload;
}

export interface IAuthContextProps {
    authState: IAuthContextState;
    dispatch: (action: { data: {}; action: AuthActions }) => void;
}

const initialState = {
    user: undefined,
    token: null,
    loading: true,
    settings: undefined,
};

export enum AuthActions {
    SETTOKEN,
    SETUSER,
    LOGOUT,
    SETLOADING,
    SETSETTINGS,
    SETORGANIZATION
}

export const AuthContext = createContext<IAuthContextProps>(
    {} as IAuthContextProps
);

export const AuthProvider = (props: any) => {
    const [state, dispatch] = useReducer(
        (currentState: IAuthContextState, payload: IAuthProviderPayload) => {
            let updatedUser = cloneDeep(currentState.user);

            switch (payload.action) {
                case AuthActions.SETSETTINGS:
                    if (updatedUser) {
                        updatedUser.relationships.organization.relationships.tenant_settings = payload?.data?.settings;
                        return {
                            ...currentState,
                            user: updatedUser,
                        };
                    }

                    return {
                        ...currentState
                    };
                case AuthActions.SETORGANIZATION:
                    if (updatedUser && updatedUser.relationships.organization.attributes && payload?.data?.organization?.attributes) {
                        updatedUser.relationships.organization.attributes = payload?.data?.organization?.attributes
                        return {
                            ...currentState,
                            user: updatedUser,
                        };
                    }

                    return {
                        ...currentState
                    };
                case AuthActions.SETTOKEN:
                    localStorage.setItem(
                        "token",
                        JSON.stringify(payload.data.token)
                    );
                    return {
                        ...currentState,
                        token: payload.data.token,
                    };

                case AuthActions.SETUSER:
                    localStorage.setItem(
                        "user",
                        JSON.stringify(payload.data.user)
                    );

                    return {
                        ...currentState,
                        user: payload.data.user,
                    };

                case AuthActions.LOGOUT:
                    localStorage.clear();
                    window.location.reload();
                    return {
                        ...currentState,
                        ...initialState,
                        loading: false,
                    };

                case AuthActions.SETLOADING:
                    return {
                        ...currentState,
                        loading: payload.data.loading,
                    };

                default:
                    throw new Error("Undefined action provided.");
            }
        },
        initialState
    );

    const token = localStorage.getItem("token");

    const fetchLoggedInUser = async () => {
        const { data } = await getLoggedInUserRequest();
        dispatch({
            action: AuthActions.SETUSER,
            data: {
                user: data,
            },
        });

        dispatch({
            action: AuthActions.SETLOADING,
            data: {
                loading: false,
            },
        });
    };


    useEffect(() => {
        if (token) {
            dispatch({
                action: AuthActions.SETTOKEN,
                data: {
                    token: JSON.parse(token),
                },
            });

            fetchLoggedInUser();

        } else {
            dispatch({
                action: AuthActions.SETLOADING,
                data: {
                    loading: false,
                },
            });
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ authState: state, dispatch }}>
            {props.children}
        </AuthContext.Provider>
    );
};
