import httpClient from "../../clients/httpClient";
import { ICustomer, ICustomerQuery } from "../../interfaces/customer.interface";

export const getCustomers = (query: ICustomerQuery) => {
    return httpClient.get("/api/v1/organizations/1/customer", {
        params: {
            page: query?.page,
            per_page: query?.perPage,
            search: query?.search,
            days: query?.days,
        },
    });
};

export const getCustomer = (organizationId: number, id: number) => {
    return httpClient.get<ICustomer>(`/api/v1/organizations/${organizationId}/customer/${id}`, {
        params: {
            include: "rewards,tenant",
        },
    });
};

export const editCustomer = (organizationId: number, id: number, payload: ICustomer) => {
    return httpClient.patch<ICustomer>(`/api/v1/organizations/${organizationId}/customer/${id}`, payload);
};

export const deleteCustomer = (organizationId: number, customerId: number) => {
    return httpClient.delete(`/api/v1/organizations/${organizationId}/customer/${customerId}`);
};

export const exportUserRegistrations = (days?: number, timezone?: string) => {
    return httpClient.get(`/api/v1/reports/user-registrations/export`, {
        responseType: "blob",
        params: {
            days,
            timezone,
        }
    });
}