import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import * as Sentry from '@sentry/react';


const BASEURL = import.meta.env.VITE_API_ENDPOINT;
const ORG_BASEURL = import.meta.env.VITE_ORG_API_ENDPOINT;

const httpClient = axios.create({
    baseURL: BASEURL,
});

httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = JSON.parse(
        localStorage.getItem("token") || "{}"
    )?.access_token;

    const org = localStorage.getItem("org");

    if (org) {
        if (import.meta.env.VITE_ENVIRONMENT === "development") {
            config.baseURL = `http://${org}.${ORG_BASEURL}`;
        } else {
            config.baseURL = `https://${org}.${ORG_BASEURL}`;
        }

        if (config.url?.includes("/auth/token") && !config.data["2fa"]) {
            config.baseURL = BASEURL;
        }
    }

    config.headers['Authorization'] = token ? `Bearer ${token}` : "";

    return config;
});

httpClient.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        return response.data;
    },
    (error) => {
        if (!error?.response || error?.response?.status !== 422) {
            try {
                Sentry.captureException(error);
            } catch (e) {
                // do nothing
            }
        }

        let errorMessage =
            error?.response?.data?.errors?.messages ??
            "Failed to complete your request.";
        let messages = "";

        if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors?.messages &&
            typeof error?.response?.data?.errors?.messages === "object" &&
            !Array.isArray(error?.response?.data?.errors?.messages)
        ) {
            for (const key in error?.response?.data?.errors?.messages) {
                if (Array.isArray(error?.response?.data?.errors?.messages[key])) {
                    messages +=
                        error?.response?.data?.errors?.messages[key].join("\n");
                }
            }
        } else if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors.messages &&
            Array.isArray(error?.response?.data?.errors?.messages)
        ) {
            messages += error?.response?.data?.errors?.messages?.join("\n");
        } else if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors?.messages &&
            typeof error?.response?.data?.errors?.messages === "string"
        ) {
            messages = error?.response?.data?.errors?.messages;
        } else {
            try {
                Object.keys(error?.response?.data?.errors).forEach((key) => {
                    toast.error(error?.response?.data?.errors[key].join("\n"));
                });
            } catch (e) {
                messages = errorMessage;
            }
        }

        if (messages === "Invalid Token") {
            messages =
                "Your invitation has expired, please contact the support.";
        }

        if (messages.trim().length) {
            toast.error(messages);
        }

        throw error;
    }
);

export default httpClient;
