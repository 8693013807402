import httpClient from "../../clients/httpClient";
import { IHomepageProductFilters } from "../../interfaces/homepage-product-filters";
import {
    IStore,
    IStoreIntegrationsPayload,
    IStorePayload,
    IStoreResponse,
    IStoresPayload,
    UpdateStoreFiltersPayload,
} from "../../interfaces/store.interface";

export const getStores = ({ ...payload }: IStoresPayload) => {
    const { page, perPage } = payload;
    const endpoint =
        page && perPage
            ? `/api/v1/stores?page=${page}&per_page=${perPage}`
            : `/api/v1/stores`;
    return httpClient.get(endpoint);
};

export const getStoresByTenant = (tenantId: number) => {
    return httpClient.get<IStoreResponse>(
        `/api/v1/admin/organizations/${tenantId}/stores`
    );
};

export const getStore = (id: string) => {
    return httpClient.get(`/api/v1/stores/${id}`);
};

export const getIHeartJaneStores = () => {
    return httpClient.get(`/api/v1/jane/menu/stores`);
};

export const getExternalStores = (integration_id?: number) => {
    const params =
        integration_id !== undefined ? `?integration_id=${integration_id}` : "";

    return httpClient.get(`/api/v1/external-stores${params}`);
};

export const createStore = (payload: IStorePayload) => {
    return httpClient.post<IStore>("/api/v1/stores", payload);
};

export const editStore = (id: number, payload: IStoreIntegrationsPayload) => {
    return httpClient.post<IStore>(`/api/v1/stores/${id}`, payload);
};

export const editStoreIntegrations = (id: number, payload: IStorePayload) => {
    return httpClient.post(`/api/v1/stores/${id}/integrations`, payload);
};

export const deleteStore = (id: number) => {
    return httpClient.delete(`/api/v1/stores/${id}`);
};

export const getStoreProducts = (id: string) => {
    return httpClient.get(`/api/v1/products/?store_id=${id}`);
};

export const reorderHomePageProductFilterStore = (
    storeId: number,
    newOrder: number[]
) => {
    return httpClient.patch(
        `/api/v1/stores/${storeId}/homepage-product-filter/reorder`,
        { newOrder }
    );
};

export const updateHomePageProductFilterStore = (
    storeId: number,
    id: number,
    payload: any
) => {
    return httpClient.patch<IHomepageProductFilters>(
        `/api/v1/stores/${storeId}/homepage-product-filter/${id}`,
        payload
    );
};

export const deleteHomePageProductFilterStore = (
    storeId: number,
    id: number
) => {
    return httpClient.delete(
        `/api/v1/stores/${storeId}/homepage-product-filter/${id}`
    );
};

export const createHomePageProductFilterStore = (
    storeId: number,
    payload: any
) => {
    return httpClient.post<IHomepageProductFilters>(
        `/api/v1/stores/${storeId}/homepage-product-filter`,
        payload
    );
};

export const getStoreFilteredProducts = (id: string) => {
    return httpClient.get(
        `/api/v1/products/filters?store_id=${id}&show_subcategories=1`
    );
};

export const updateStoreFilterRequest = (
    id: number,
    payload: UpdateStoreFiltersPayload
) => {
    return httpClient.patch<IStore>(`/api/v1/stores/${id}/filters`, payload);
};

export const getStoreHomePageFilters = (id: string) => {
    return httpClient.get(`/api/v1/stores/${id}/homepage-product-filter`);
};
