import { useEffect } from 'react';

const TawkToScript = () => {
	useEffect(() => {
		// Ensure the script is not already appended
		if (!document.getElementById('tawk-script')) {
			const script = document.createElement('script');
			script.id = 'tawk-script';
			script.async = true;
			script.src = 'https://embed.tawk.to/66e9cefd83ab531891e811c1/1i80l13nf';
			script.charset = 'UTF-8';
			script.setAttribute('crossorigin', '*');

			// Append the script to the head
			document.head.appendChild(script);
		}
	}, []); // Run once when the component mounts

	return null; // This component doesn't render anything
};

export default TawkToScript;