import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getStores as fetchStores,
    getStore as fetchStore,
    getStoreHomePageFilters as fetchStoreHomePageFilters,
} from "../../services/store/store.service";
import {
    getStoresSuccess,
    getStoresFailure,
    getStores,
    getStoreSuccess,
    getStoreFailure,
    getStore,
    getStoreHomePageFilters,
    getStoreHomePageFiltersSuccess,
    getStoreHomePageFiltersFailure,
} from "../reducer/stores";
import {
    GetStoreActionPayload,
    GetStoreHomePageFiltersPayload,
    GetStoresPayload,
} from "../types/stores";

function* getStoresSaga(actions: GetStoresPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchStores,
            actions.payload
        );
        yield put(getStoresSuccess(data));
    } catch (error: any) {
        yield put(getStoresFailure(error));
    }
}

function* getStoreSaga(actions: GetStoreActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(fetchStore, actions.payload);
        yield put(getStoreSuccess(data));
    } catch (error: any) {
        yield put(getStoreFailure(error));
    }
}

function* getStoreHomePageFiltersSaga(actions: GetStoreHomePageFiltersPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchStoreHomePageFilters,
            actions.payload
        );
        yield put(getStoreHomePageFiltersSuccess(data));
    } catch (error: any) {
        yield put(getStoreHomePageFiltersFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getStores.type, getStoresSaga);
    yield takeLatest(getStore.type, getStoreSaga);
    yield takeLatest(getStoreHomePageFilters.type, getStoreHomePageFiltersSaga);
}
